import { NavigationItem } from '../NavigationItem';

import styles from './topics.module.scss';
import type { TopicsProps } from './types';

function Topics({ topics, topicsLabel }: TopicsProps) {
  return (
    <div className={styles.topics} data-testid="topics">
      <NavigationItem className={styles.topicsLabel}>{topicsLabel}</NavigationItem>
      {topics.map(({ uniqueId, text, url, target, clientUrl, serverUrl }) => (
        <NavigationItem
          trackingBlock="Topics"
          trackingName={text}
          key={uniqueId}
          clientHref={clientUrl ?? url}
          serverHref={serverUrl ?? url}
          target={target}
          className={styles.topicsItem}
        >
          {text}
        </NavigationItem>
      ))}
    </div>
  );
}

export default Topics;
